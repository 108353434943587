import { lazy } from 'react'

export default {
  'wordmark-dual-blue': {
    id:1,
    type: 'wordmark',
    path: lazy(() => import('./wordmark-dual-blue')),
    viewBox: '390 118',
  },
  'wordmark-dual-white': {
    id:2,
    type: 'wordmark',
    path: lazy(() => import('./wordmark-dual-white')),
    viewBox: '390 118',
  },
  'wordmark-blue':{
    id:3,
    type: 'wordmark',
    path: lazy(() => import('./wordmark-blue')),
    viewBox: '390 118',
  },
  'wordmark-white':{
    id:4,
    type: 'wordmark',
    path: lazy(() => import('./wordmark-white')),
    viewBox: '390 118',
  },
  'logomark-dual-blue':{
    id:5,
    type: 'logomark',
    path: lazy(() => import('./logomark-dual-blue')),
    viewBox: '142 142',
  },
  'logomark-dual-white':{
    id:6,
    type: 'logomark',
    path: lazy(() => import('./logomark-dual-white')),
    viewBox: '142 142',
  },
  'logomark-blue':{
    id:7,
    type: 'logomark',
    path: lazy(() => import('./logomark-blue')),
    viewBox: '142 142',
  },
  'logomark-white':{
    id:8,
    type: 'logomark',
    path: lazy(() => import('./logomark-white')),
    viewBox: '142 142',
  },
}
