import clsx                   from 'clsx';
import PropTypes              from 'prop-types';
import React                 from 'react';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';


const LokaliseText = React.forwardRef(function LokaliseText(props, ref) {

  const { children, slug, titleize, tag: Tag, ...refProps } = props;

  const LokaliseFn = LokaliseLanguage.getSlugTranslation({slug, canTitleize: titleize});

  return (
    <>
    {Tag && (
    <Tag className={clsx(refProps.className)}>
      {LokaliseFn || children || ''}
    </Tag>
    )}
    {!Tag && LokaliseFn || children || ''}
    </>
  );
});

LokaliseText.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node,
  titleize: PropTypes.bool,
};
LokaliseText.defaultProps = {
  slug: null,
  children: null,
  titleize: false,
};

export default React.memo(LokaliseText);