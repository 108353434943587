import React, { useEffect, useContext, useState, useRef, createRef, } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import Session from 'UTILS/Session';
import { InstarecContext, InstarecProvider } from 'COMPONENTS/InstarecContext';
import withContext from 'HOC/withContext';

import Results from './results.js';


const InstarecResults = (props) => {
	const context = useContext(InstarecContext);

	const resultsMounted = useRef(true);

	useEffect(
		() => () => {
			if (!resultsMounted.current) {
				resultsMounted.current = false;
				setTimeout(()=> {
					const updateEvent = new CustomEvent('appUnmout_results', { detail: { type: 'results' }} );
	    			document.dispatchEvent(updateEvent);
					props.root?.unmount();
				});
			}
		},
		[resultsMounted.current]
	);

	return (
		<>
		<React.StrictMode>
			{resultsMounted.current && (
				<div
					className={ clsx(
						'irjs__results--container',
						context.getCustomStyling('results.container'),
						context.params.devMode && 'irjs__hidden'
					) }
				>
					<Results onRenderComplete={ props.onRenderComplete } renderProductCards={ props.renderProductCards } />
				</div>
			)}
		</React.StrictMode>
		</>
	);
};

InstarecResults.propTypes = {};
InstarecResults.defaultProps = {};

export default withContext(InstarecProvider, InstarecResults);
