import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';
import { getResultsTotal, mergeResults } from 'UTILS/ResultsUtil';

import Context from 'UTILS/Context';
import { InstarecContext } from 'COMPONENTS/InstarecContext';
import LoadingDots from 'GLOBAL/LoadingDots';
import PoweredByLogo from 'GLOBAL/PoweredByLogo';
import PreferabliLogo from 'GLOBAL/PreferabliLogo';
import QuestionaireApi from 'API/QuestionaireApi';
import Session from 'UTILS/Session';
import ResultsSideBar from './sidebar';
import ResultsSection from './achoredresults';
import Placeholder from './placeholder';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';


/* eslint-enable import/no-unresolved */

const Results = (props) => {
	const context = useContext(InstarecContext);

	const [queryResults, setQueryResults] = useState(false);
	const [postObj, setPostObject] = useState(Session.storage.session[`questionnaire_${context.params.questionnaire_id}_post`] || false);
	const [initLoading, setInitLoading] = useState(true);
	const [hasApiError, setApiError] = useState(false);
	const [onLoadMore, setOnLoadMore] = useState(false);
	const [allowScroll, setAllowScroll] = useState(false);

	const prevResults = useRef(null);
	const currResult = useRef(null);
	const resultActiveSectionIdx = useRef(0);
	const resultsContainerRef = useRef(null);

	const scrollToElement = (section) => {
		const element = document.getElementById(section);

		let block = 'center';
		if (window.outerHeight < element.scrollHeight) block = 'start';
		var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
		if (isSmoothScrollSupported) {
			element.scrollIntoView({
				behavior: 'smooth',
				block,
			});
		} else {
			element.scrollIntoView(false);
		}
	};

	const handleShowModal = () => {
		if (window[Context._appPrefix]) {

			PreferabliAnalytics.track('guidedrec modal opened', {
				onlyMp: true,
	            questionnaire_id: Number(context.params.questionnaire_id),
				page_url: window.location.href,
                page_pathname: window.location.pathname,
	        });

			window[Context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id: Context.getQuestionnaireId() || context.params.questionnaire_id,
			});
		}
	};

	const getQuestionnaireResults = (postObj) => QuestionaireApi.postQuestionaire(postObj);
	const handleQuestinnaireResults = ({
		questionnaireResults = null,
		prevResults = null,
	}) => {
		if (typeof questionnaireResults === 'object' && Object.keys(questionnaireResults).length) {
			let results = context.params.resultsDisplayedAs === 'merged' ? mergeResults(questionnaireResults) : questionnaireResults;

			const type_order = ['red', 'white', 'rose', 'sparkling', 'fortified'];
			results.types = results.types.sort((a, b) => type_order.indexOf(a.nonLatinType) - type_order.indexOf(b.nonLatinType)); // initial sort

			if (Object.prototype.toString.call(prevResults) === '[object Object]' && prevResults.types.length && context.params.resultsDisplayedAs === 'separated') {
				results = [...prevResults.types].reduce((currentArr, prevArr) => {
					const currentTypeArr = currentArr.types.find(
						(cTypeArr) => cTypeArr.nonLatinType === prevArr.nonLatinType
					);
					if (currentTypeArr) {
						const newData = [...currentTypeArr.results];
						const prevData = [...prevArr.results];
						currentTypeArr.results = prevData.concat(newData);
					}
					if (!currentTypeArr) {
						currentArr.types = [...currentArr.types, ...[prevArr]];
					}
					return currentArr;
				}, results);

				results.types = results.types.sort((a, b) => type_order.indexOf(a.nonLatinType) - type_order.indexOf(b.nonLatinType)); // secondary sort when adding type update

				// apply plural
				if (context.params.typesPlural) {
					results.types = results.types.map((resultType) => {
						if (['red', 'white', 'rose'].indexOf(resultType.nonLatinType) >= 0 && !resultType.type.endsWith('s')) {
							resultType.type = `${resultType.type}s`;
						} else if(!resultType.type.endsWith('s')){
							resultType.type = `${resultType.type}s`;
						}
						return resultType;
					});
				}

				setQueryResults(results);
				setOnLoadMore(false);
			}

			if (!prevResults) {
				if (context.params.typesPlural) {
					results.types = results.types.map((resultType) => {
						if (['red', 'white', 'rose'].indexOf(resultType.nonLatinType) >= 0 && !resultType.type.endsWith('s')) {
							resultType.type = `${resultType.type}s`;
						} else if(!resultType.type.endsWith('s')){
							resultType.type = `${resultType.type}s`;
						}
						return resultType;
					});
				}

				setQueryResults(results);
			}

			if (!Session.storage.session[`questionnaire_${Context.getQuestionnaireId()}_postedquery`]) {
				context.setSessionData({postedquery: true}, `questionnaire_${Context.getQuestionnaireId()}`);
			}

			if (initLoading) setInitLoading(false);
			if (results && results.types && results.types.length && !prevResults) context.setActiveSectionTyoe(results.types[0].nonLatinType);
		}
	};

	useEffect(() => {
		currResult.current = queryResults;
		prevResults.current = queryResults;
	}, [queryResults]);

	useEffect(() => {
		if (postObj && Object.keys(postObj).length) {

			const handleLoad = async () => {
				let _questionnaire;

				await QuestionaireApi.getQuestionaire({
					id: Number(context.params.questionnaire_id),
				}).then(async (questionnaire) => {

					_questionnaire = questionnaire;

					const awaitChoicesFilters = await context.setChoicesFilters(questionnaire.questions);

	                context.setQuestionnaire({...questionnaire});
	                context.setQuestions([...questionnaire.questions]);

					context.setQuizContext({
						...context.quizContext,
						questionnaire,
						max_results_per_type: questionnaire.max_results_per_type,
						offset: 0,
					});
				});


				if(typeof postObj.filters === 'object'
					&& postObj.filters 
					&& Object.entries(postObj.filters).length){
					const _temp = {...postObj.filters};
					postObj.filters = [];

					if(_temp.min) {
						postObj.filters.push({key:'price_min', value:_temp.min})
					}
					if(_temp.max) {
						postObj.filters.push({key:'price_max', value:_temp.max})
					}
				}

				getQuestionnaireResults({
						...postObj, 
						max_results_per_type: _questionnaire.max_results_per_type, 
						max_results_per_page: _questionnaire.max_results_per_page, 
						include_wines: true,
					})
					.then((_qResults) => {

						 const type_order = ['reds', 'whites', 'roses', 'sparkling', 'fortified'];
				          _qResults.types = _qResults.types
				            .sort((a, b) => type_order.indexOf(a.nonLatinType) - type_order.indexOf(b.nonLatinType))

						handleQuestinnaireResults({ 
							questionnaireResults: _qResults, 
						});

						let _types = {};

						if(_qResults.types.length){
							_qResults.types.reduce((obj, _type) => {
								obj[`${_type.nonLatinType}_has_more`] = _type.hasNextPage;
								obj[`${_type.nonLatinType}_number_of_results`] = _type.results.length;
								obj[`${_type.nonLatinType}_product_lookups`] = _type.results.reduce((arr, _result, idx) => {
									let _pName = '';
					                const { product, lookups } = _result;
					                if(lookups.length) _pName = lookups[0].product_name;
									return [...new Set([...arr, _pName])];
								}, []);

								obj[`${_type.nonLatinType}_products`] = _type.results.reduce((arr, _result, idx) => {
								const { product } = _result;
								return [...new Set([...arr, product.name])];
								}, []);

							  return obj;
							}, _types);
						}

						PreferabliAnalytics.track('guided rec results',{
						onlyMp: true,
						guidedrec_results_total: _qResults.results_total,
						guidedrec_number_of_types: _qResults.types.length,
						guidedrec_product_lookups:  _qResults.types.reduce((arr, _type, idx) => {
						  const _typeProductNames = _type.results.map((_result) => {
							    let _pName = '';
				                const { product, lookups } = _result;
				                if(lookups.length) _pName = lookups[0].product_name;
				                return _pName;
							  })
						  return [...new Set([...arr, ..._typeProductNames])];
						}, []),
						guidedrec_products:  _qResults.types.reduce((arr, _type, idx) => {
						  const _typeProductNames = _type.results.map((_result) => {
						    const { product, lookups } = _result;
						    return product.name;
						  })

						  return [...new Set([...arr, ..._typeProductNames])];
						}, []),
						..._types,
						});

						const _choices =  _qResults.questionnaire_choices.reduce((obj, choice, idx) => {
						  obj[`guidedrec_submitted_choice_${idx}_id`] = choice.id; 
						  obj[`guidedrec_submitted_choice_${idx}_text`] = choice.text; 
						  return obj;
						}, {});

						let _filters = {
							guidedrec_filter_pricemin: null,
							guidedrec_filter_pricemax: null,
						};

						if(_qResults.filters && Object.keys(_qResults.filters).length){
							_filters = _qResults.filters.reduce((obj, item) => {

							  if(item.key === 'price_min'){
							    obj.guidedrec_filter_pricemin = item.value;
							  }
							  if(item.key === 'price_max'){
							    obj.guidedrec_filter_pricemax = item.value;
							  }

							  return obj;
							}, _filters);
						}


						PreferabliAnalytics.track('guided rec submitted',{
							onlyMp: true,
							questionnaire_id: Number(context.params.questionnaire_id),
							questionnaire_query_id: _qResults.id,
							guidedrec_number_of_choices: postObj.questionnaire_choice_ids.length,
							guidedrec_choice_ids: postObj.questionnaire_choice_ids,
							guiedrec_choices:  _qResults.questionnaire_choices.reduce((arr, choice, idx) => {
							  return [...new Set([...arr, choice.text])];
							}, []),
							guidedrec_filters: postObj.filters,
							..._choices,
							..._filters,
						});
					})
					.catch((error) => {
						console.log('error', error);
						setApiError(true);
						setInitLoading(false);
						
						PreferabliAnalytics.track('guidedrec results not loaded', {
							onlyMp: true,
							error: 'api',
				            questionnaire_id: Number(context.params.questionnaire_id),
				            ...postObj,
				        });
					});
			};
			/// todo move to instantContext on useEffect mounted

			handleLoad();
			
		} else if (initLoading) {
			setInitLoading(false);
			handleShowModal();
		}
	}, []);

	const showMoreResults = (loadData) => {
		const { offset, type } = loadData;

		return new Promise((resolve, reject) => {
			const { max_results_per_type, max_results_per_page } = context.questionnaire;
			let limit = null;

			if (!max_results_per_page 
				&& max_results_per_type 
				&& Number(offset) < max_results_per_type) {
				limit = 3;
				// limit = max_results_per_type - Number(offset);
			}

			if(max_results_per_page && limit > max_results_per_page){
				limit = max_results_per_page;
			}

			getQuestionnaireResults({
				...postObj,
				offset: Number(offset) + 1,
				...(limit !== null ? { limit } : {}),
				max_results_per_type,
				max_results_per_page,
				include_wines: true,
			}).then(
				(questionnaireResults) => {
					const filterType = questionnaireResults.types.filter(
						(qType) => qType.nonLatinType === type
					);

					questionnaireResults.types = filterType;

					if(filterType.length){
						handleQuestinnaireResults({
							questionnaireResults,
							prevResults: prevResults.current,
						});

				        PreferabliAnalytics.track('guided rec show more results',{
				            onlyMp: true,
				            questionnaire_id: Number(context.params.questionnaire_id),
				            guidedrec_show_more_results_id: questionnaireResults.id,
				            guidedrec_show_more_type: filterType[0].nonLatinType,
				            guidedrec_show_more_results_total: filterType[0].results.length,
				            show_more_type: type,
				            guidedrec_product_lookups:  questionnaireResults.types.reduce((arr, _type, idx) => {
							  const _typeProductNames = _type.results.map((_result) => {
							    let _pName = '';
				                const { product, lookups } = _result;
				                if(lookups.length) _pName = lookups[0].product_name;
				                return _pName;
							  })
							  return [...new Set([...arr, ..._typeProductNames])];
							}, []),
							guidedrec_products:  questionnaireResults.types.reduce((arr, _type, idx) => {
							  const _typeProductNames = _type.results.map((_result) => {
							    const { product, lookups } = _result;
							    return product.name;
							  })

							  return [...new Set([...arr, ..._typeProductNames])];
							}, []),
				          });
					}
					resolve();
				},
				() => {
					reject();
					PreferabliAnalytics.track('guidedrec more results not loaded', {
						onlyMp: true,
						error: 'api',
						show_more_type: type,
			            questionnaire_id: Number(context.params.questionnaire_id),
			            ...postObj,
			        });
				}
			);
		});
	};

	const handleScrollChange = (direction, section) => {
		const results = Object.keys(queryResults).length
			? queryResults
			: currResult.current;
		if (results.types && results.types.length > 1 && !onLoadMore && allowScroll) {
			const currentSectionIdx = results.types.findIndex(
				(type) => type.nonLatinType === section
			);

			const nextSectionIdx = direction
				? Math.min(currentSectionIdx + 1, results.types.length - 1)
				: Math.max(0, currentSectionIdx - 1);

			resultActiveSectionIdx.current = nextSectionIdx;
			context.setActiveSectionTyoe(results.types[nextSectionIdx].nonLatinType);
		}
	};

	const handleSectionClick = (e) => {
		let sectionLinkData;
		if (e.target.nodeName === 'SPAN' && e.target.parentNode.nodeName === 'LI') {
			sectionLinkData = { ...e.target.parentNode.dataset };
		}
		if (e.target.nodeName === 'LI') {
			sectionLinkData = { ...e.target.dataset };
		}

		if (context.resultActiveSectionType !== sectionLinkData.section) {
			resultActiveSectionIdx.current = Number(sectionLinkData.sectionIdx);
			context.setActiveSectionTyoe(sectionLinkData.section);
			scrollToElement(sectionLinkData.section);
		} else {
			scrollToElement(sectionLinkData.section);
		}
	};

	const enableScrollChange = () => {
		if (!allowScroll) setAllowScroll(true);
	};

	const defaultResultProps = {
		heading: LokaliseLanguage.getSlugTranslation({slug:'result.defaultheading'}),
		copy: <p>{LokaliseLanguage.getSlugTranslation({slug:'result.defaultsubheading'})}</p>,
		instantRecButton: {
			text: LokaliseLanguage.getSlugTranslation({slug:'result.defaultbtn'}),
		},
	};

	let noResultProps = {
		heading: '',
		copySize: 'h2',
		copy: (
			<>
				{LokaliseLanguage.getSlugTranslation({slug:'result.noresultsheading'})}
				<br />
				{LokaliseLanguage.getSlugTranslation({slug:'result.noresultssubheading'})}
			</>
		),
		instantRecButton: {
			text: LokaliseLanguage.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'noresults',
	};

	if (context.params.noResults&& Object.keys(context.params.noResults).length) {
		const { text, textSize, ...paramProps } = context.params.noResults;

		noResultProps = {
			...noResultProps,
			...paramProps,
		};

		if (text) {
			noResultProps = {
				...noResultProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}

	let errorResultProps = {
		copy: (
			<>
				<h2>{LokaliseLanguage.getSlugTranslation({slug:'result.errorheading'})}</h2>
				<h4>{LokaliseLanguage.getSlugTranslation({slug:'result.errorsubheading'})}</h4>
			</>
		),
		instantRecButton: {
			text: LokaliseLanguage.getSlugTranslation({slug:'result.updatebtn'}),
		},
		type: 'error',
	};

	if (context.params.error && Object.keys(context.params.error).length) {
		const { text, textSize, ...paramProps } = context.params.error;

		errorResultProps = {
			...errorResultProps,
			...paramProps,
		};

		if (text) {
			errorResultProps = {
				...errorResultProps,
				copy: React.isValidElement(parse(text)) ? (
					<>{parse(text)}</>
				) : (
					<span style={ { fontSize: textSize } }>{text}</span>
				),
			};
		}
	}

	const hasResultError = () => hasApiError || context.hasGlobalError;

	return (
		<>
			<div className={ clsx('irjs__results') }>
				<div
					className={ clsx(
						'irjs__results--heading',
						'irjs__results--heading-with-pbwr',
						context.getCustomStyling('results.heading')
					) }>
					<h1>
						{(context.params.customText && context.params.customText.resultsHeading) || LokaliseLanguage.getSlugTranslation({slug:'result.resultsheading'})}
					</h1>
				</div>
				{initLoading && (
					<LoadingDots className={ clsx('fade', initLoading && 'in', !initLoading && 'd-none') } text={(context.params.customText && context.params.customText.resultsLoader) || undefined}/>
				)}
				{context.params.resultsDisplayedAs === 'separated' && queryResults && queryResults.types && queryResults.types.length > 0 && !hasResultError() && !initLoading && (
					<div className={ clsx( 'irjs__results--outer', context.params.layout === 'column' && 'irjs__results--column') }>
						<ResultsSideBar activeSection={ context.resultActiveSectionType } sections={ queryResults.types } onClick={ handleSectionClick } parentRef={ resultsContainerRef } />
						<div className="irjs__results--content" ref={ resultsContainerRef }>
						{context.params.results_logo_placement === 'results' && (
							<div className="irjs__logo irjs__logo--top-right">
								{!context.params.preferabli_logo && <PoweredByLogo logoNumber={context.params.logo_option}/>}
								{context.params.preferabli_logo && <PreferabliLogo logoNumber={context.params.logo_option}/>}
							</div>
						)}
						{queryResults.types.map((grouping, idx) => (
							<ResultsSection
								key={ grouping.nonLatinType }
								id={ grouping.nonLatinType }
								loadMore={ showMoreResults }
								activeSection={ context.resultActiveSectionType }
								onScrollChange={ handleScrollChange }
								renderProductCards={ props.renderProductCards }
								onRenderComplete={ props.onRenderComplete }
								onComplete={ enableScrollChange }
								{ ...grouping }/>
						))}
						</div>
					</div>
				)}
				{queryResults && queryResults.types && !queryResults.types.length && !initLoading && !hasApiError && <Placeholder { ...noResultProps } />}
				{!queryResults && !initLoading && !hasApiError && (<Placeholder { ...defaultResultProps } />)}
				{hasResultError() && !initLoading && (<Placeholder { ...errorResultProps } />)}
			</div>
		</>
	);
};

Results.propTypes = {
	onRenderComplete: PropTypes.func,
	renderProductCards: PropTypes.func,
};
Results.defaultProps = {
	onRenderComplete: null,
	renderProductCards: null,
};

export default Results;
