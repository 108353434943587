import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';

import { titleize, underscore } from 'HELPERS/text';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';

import Context                                       from 'UTILS/Context';
import { InstarecContext }                           from 'COMPONENTS/InstarecContext';
import MaterialIcon                                  from 'GLOBAL/MaterialIcon';
/* eslint-disable import/no-unresolved */
import PropTypes                                     from 'prop-types';
import clsx                                          from 'clsx';
import Throttlers                                  from 'HELPERS/throttlers';
import { useScrollPosition }                         from 'HOOKS/useScrollPosition';

import PoweredByLogo from 'GLOBAL/PoweredByLogo';
import PreferabliLogo from 'GLOBAL/PreferabliLogo';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';

/* eslint-enable import/no-unresolved */

const ResultsSideBar = (props) => {
	const context = useContext(InstarecContext);

	const [willStick, setWillStick] = useState(false);
	const [sidebarOpts, setSidebarOptions] = useState(0);
	const [sideContainer, setSideContainer] = useState();
	const [sideMenu, setSideMenu] = useState();
	const sidebarMenuRef = useRef(null);
	const [currentType, setCurrentType] = useState();

	const backToTop = (e) => {
		e.preventDefault();
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const [sidebarStyle, setSidebarStyle] = useState();

	const handleShowModal = (e) => {
		e.preventDefault();

		if (window[Context._appPrefix]) {
			window[Context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id:
          Context.getQuestionnaireId() || context.params.questionnaire_id,
				selectedQuestionIdx: context.params.modifyQuestionShow || 1,
			});
		}
	};

	useScrollPosition(
		({ currPos }) => {
			if (
				!context.params.sidemenuStatic
        && Number(context.params.sidemenuStickyTop) >= 0
			) {
				const sideMenuRects = sideMenu.getBoundingClientRect();
				const minScroll = sidebarOpts.top - 50;
				const maxScroll =          Math.abs(currPos.y) + sideMenuRects.height + sideMenuRects.height / 3;

				const isRowLayout =          context.params.layout === 'column'
          	? true
          	: maxScroll <= props.parentRef.current.scrollHeight;
				const isSticking =          window.innerWidth > 568
          && window.scrollY > 0
          && window.scrollY >= minScroll
          && isRowLayout;

				let stickyTop = context.params.sidemenuStickyTop;
				if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
					stickyTop = convertCssUnit(stickyTop);
				}

				if (sideContainer && context.params.layout === 'column') {
					sideContainer.style.height = isSticking
						? sideMenuRects.height
						: 'auto';
				}

				setSidebarStyle({
					...sidebarStyle,
					transform: isSticking ? `translate3d(0, ${stickyTop}px, 0)` : '',
					width: window.innerWidth > 568 ? sideContainer.clientWidth : '100%',
				});

				setWillStick(isSticking);
			}
		},
		[willStick, sidebarOpts],
		sideMenu
	);

	useEffect(() => {
		if (sideContainer && sideMenu) {
			setSidebarOptions({
				top: sideContainer.getBoundingClientRect().top,
				bottom:
          sideContainer.getBoundingClientRect().top
          + sideContainer.getBoundingClientRect().height,
				width: sideMenu.clientWidth,
				height: sideMenu.getBoundingClientRect().height,
			});
			if (
				!context.params.sidemenuStatic
        && Number(context.params.sidemenuStickyTop) >= 0
        && context.params.layout === 'column'
			) {
				window.addEventListener('resize', onResizeThrottle);
			}
		}

		return () => {
			window.removeEventListener('resize', onResizeThrottle);
		};
	}, [sideContainer, sideMenu]);

	const handleResize = (e) => {
		if (sideContainer && sideMenu && sideMenu.classList.contains('sticky')) {
			setSidebarStyle({
				...sidebarStyle,
				width: window.innerWidth > 568 ? sideContainer.clientWidth : '100%',
			});
		}
	};

		const onResizeThrottle = useCallback(Throttlers.throttle(handleResize, 1000), [
		sideMenu,
		sideContainer,
	]);

	useEffect(() => {
		setCurrentType(props.activeSection);
	}, [props.activeSection]);

	const setTypeText = (type, fallbackHeading) => {
		if (
			context.params.customText
	      && Object.keys(context.params.customText).includes('categories')
	      && Object.keys(context.params.customText.categories).length
		) {
			const {
				red,
				white,
				rose,
				sparkling,
				fortified,
			} = context.params.customText.categories;

			if (context.params.typesPlural) type = type.slice(0, -1); // create singleton

			switch(type){
				case 'red':
					return typeof red !== 'undefined' && red.length
						? red
						: `${titleize(fallbackHeading)}s`;
				break;
				case 'white':
					return typeof white !== 'undefined' && white.length
						? white
						: `${titleize(fallbackHeading)}s`;
				break;
				case 'rose': 
				case 'rosé':
					return typeof rose !== 'undefined' && rose.length
						? rose
						: `${titleize(fallbackHeading)}s`;
				break;
				case 'sparkling':
					return typeof sparkling !== 'undefined' && sparkling.length
						? sparkling
						: `${titleize(fallbackHeading)}s`;
				break;
				case 'fortified':
					return typeof fortified !== 'undefined' && fortified.length
						? fortified
						: `${titleize(fallbackHeading)}s`;
				break;
				default:
					return `${titleize(fallbackHeading)}s`;
			}
			
		}
		return `${titleize(fallbackHeading)}s`;
	};

	return (
		<div ref={ setSideContainer } className={ clsx('irjs__results--sidebar') }>
			<div
				ref={ setSideMenu }
				className={ clsx('irjs__sidemenu--wrapper', willStick && 'sticky') }
				style={ sidebarStyle }
			>
				<div className="irjs__sidemenu">
					<h3>{LokaliseLanguage.getSlugTranslation({slug:'result.sidebarheading'})}</h3>
					<ul className="irjs__sidemenu--nav">
						{props.sections.map((resultType, idx) => {
							const { type, results, nonLatinType } = resultType;

							return (
								<li
									className={ clsx(
										'irjs__sidemenu--nav-item',
										currentType === nonLatinType
                      && 'irjs__sidemenu--nav-active',
										context.getCustomStyling('sidemenu.item'),
										currentType === nonLatinType
                      && context.getCustomStyling('sidemenu.itemActive')
									) }
									data-section={ nonLatinType }
									data-section-idx={ idx }
									onClick={ props.onClick }
									key={ nonLatinType }
								>
									<span>
										{setTypeText(type.toLowerCase(), LokaliseLanguage.getSlugTranslation({slug:`type.${underscore(nonLatinType)}`}) )}
									</span>
								</li>
							);
						})}
					</ul>
					<div className={ clsx('irjs__sidemenu--actions') }>
						<button
							type="button"
							className={ clsx(
								'irjs__btn',
								'irjs__btn--solid',
								context.getCustomStyling([
									'sidemenu.modifySelectionsBtn',
									'basicButton',
								])
							) }
							id="sidebarmodify"
							onClick={ handleShowModal }
						>
							{(context.params.customText
                && context.params.customText.modifySelectionsBtn)
                || LokaliseLanguage.getSlugTranslation({slug:'result.modifybtn'})}
						</button>
						<button
							type="button"
							className={ clsx(
								'irjs__btn',
								context.getCustomStyling([
									'sidemenu.backToTopBtn',
									'basicButton',
								])
							) }
							id="sidebacktotop"
							onClick={ backToTop }
							disabled={ !willStick }
						>
							<MaterialIcon icon="arrow_upward" />
							{' '}
							{LokaliseLanguage.getSlugTranslation({slug:'result.backtopbtn'})}
						</button>
					</div>
				</div>

				{context.params.results_logo_placement === 'sidebar' && (
					<div className="irjs__logo irjs__logo--static">
					{!context.params.preferabli_logo && <PoweredByLogo logoNumber={context.params.logo_option}/>}
					{context.params.preferabli_logo && <PreferabliLogo logoNumber={context.params.logo_option}/>}
					</div>
				)}
			</div>
		</div>
	);
};

ResultsSideBar.propTypes = {
	onClick: PropTypes.func,
	sections: PropTypes.arrayOf(PropTypes.shape()),
	activeSection: PropTypes.string,
	parentRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
};
ResultsSideBar.defaultProps = {
	onClick: () => {},
	sections: null,
	activeSection: '',
	parentRef: null,
};

export default ResultsSideBar;
