import React, { useEffect, useContext } from 'react';
import PropTypes                        from 'prop-types';
import clsx                             from 'clsx';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import usePrevious from 'HOOKS/usePrevious';
import { InstarecContext } from 'COMPONENTS/InstarecContext';
import Question from './question';

const Questions = () => {
	
	const {questions, ...context} = useContext(InstarecContext);
	const prevSelections = usePrevious(context);

	/// deal with currentQuestion

	const handleValueChange = (questionId, values) => {
	  const { choices, filters } = context;
	  const qValues = Object.assign({}, { choices, filters });

	  const _question = context.questionnaire.questions.find((question) => question.id === questionId);

	  if (Array.isArray(values) && _question.type === 'multiple_choice') {
	    const questionValues = values;
	    if (!qValues.choices[questionId]) qValues.choices[questionId] = [];
	    qValues.choices[questionId] = questionValues;
	    const _question = context.questionnaire.questions.find((question) => question.id === questionId);

	    const nonCurrentQuestions = context.questionnaire.questions.filter(
	      (question) => question.type === 'multiple_choice' && question.id !== questionId && question.number > _question.number
	    );

	    if (questionValues.length) {
	      const updateQuestionSelections = nonCurrentQuestions.reduce((obj, question, idx) => {
	        if (!obj[question.id] && qValues.choices[question.id] && qValues.choices[question.id].length) {
	          // obj[question.id] = []; //
	          question.choices.forEach((questionChoice) => {
	            const {
	              id,
	              requires_choice_ids,
	            } = questionChoice;
	            if (qValues.choices[question.id].includes(id) 
	              && (!requires_choice_ids.length || 
	                (requires_choice_ids.length && requires_choice_ids.some((val) => questionValues.includes(Number(val))))
	              )) {
	              if(!obj[question.id]) obj[question.id] = [];
	              obj[question.id].push(questionChoice.id);
	            }
	          });
	        }
	        return obj;
	      }, {});

	      Object.entries(updateQuestionSelections).forEach(([questionID, questionSelection]) => {
	        qValues.choices[questionID] = questionSelection;
	      });
	    } else if (!questionValues.length) {
	      qValues.choices[_question.id] = questionValues;
	    }
	  }

	  if (values && typeof values === 'object' && _question.type === 'price_range') {
	    const { default_price_min, default_price_max } = context.questionnaire;
	    const priceMin = default_price_min || 0;
	    const priceMax = default_price_max || 500;

	    qValues.filters = {
	      min: (values && values.min >= 0 && (Number(values.min) > priceMin)) ? values.min : null,
	      max: (values && values.max && (Number(values.max) < priceMax)) ? values.max : null,
	    };

	    // qValues.filters = [];
	    // qValues.filters.push({key: 'price_min', value: (values && values.min >= 0 && (Number(values.min) > priceMin)) ? values.min : null});
	    // qValues.filters.push({key: 'price_max', value: (values && values.max && (Number(values.max) < priceMax)) ? values.max : null});
	    
	  }

	  context.setChoices(qValues.choices);
	  context.setFilters(qValues.filters);
	  
	  // update questions
	  const _tempQuestions = [...new Set([...context.questionnaire.questions])];
	  const _hasQuestionRequired = _tempQuestions.some((_question) => (_question.requires_choice_ids.length));
	  if(_hasQuestionRequired){
	    const hasRequiredChoices = (question) => {
	      const { requires_choice_ids, id } = question;

	      if (Object.keys(qValues.choices).length) {
	        const allChoices = Object.values(qValues.choices).reduce((arr, key, idx) => {
	          if (key.length) {
	            arr = arr.concat(key);
	          }
	          return arr;
	        }, []);

	        return (
	          (allChoices.length && requires_choice_ids.length && requires_choice_ids.some((val) => allChoices.includes(Number(val))))
	          || false
	        );
	      }

	      return false;
	    };

	    const _sortedQuestions =  _tempQuestions.reduce((arr, _question) => {
	      if(!_question.requires_choice_ids.length || (_question.requires_choice_ids.length && hasRequiredChoices(_question))) arr.push(_question);
	      return arr;
	    }, []);

	    context.setQuestions(_sortedQuestions);
	  }

	  setTimeout(() => {
			const updateEvent = new CustomEvent('contextUpdated', { detail: true });
			document.dispatchEvent(updateEvent);
		});

	};	

	return (
		<>
		{questions && questions.length && (
	        <form style={{ width: '100%', }}>
			<Question
				values={ questions[context.activeQuestionIdx].type === 'multiple_choice' ? context.choices[questions[context.activeQuestionIdx].id] : context.filters }
				onChange={ handleValueChange }
				{ ...questions[context.activeQuestionIdx] }
			/>
			</form>
		)}
		</>
	);
};

export default Questions;
