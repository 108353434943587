import * as React        from 'react';
import PropTypes         from 'prop-types';
import Link              from 'GLOBAL/Link';

const CardImage = ({
	product,
	className,
	capture,
}) => {
	const {
		url,
		name,
		image_url,
	} = product;
	return (
		<div className={ `${className.trim()}` }>
			{(url && url.length) && (
				<Link href={ url } title={ name } capture={ capture }><img src={ image_url || 'https://s3.amazonaws.com/winering-production/08cf8dd461082263c0abbc2d7e62b622' } alt={ name } /></Link>
			)}
			{(!url) && (
				<img src={ image_url || 'https://s3.amazonaws.com/winering-production/08cf8dd461082263c0abbc2d7e62b622' } alt={ name } />
			)}
		</div>
	);
};

CardImage.propTypes = {
	product: PropTypes.shape(),
	className: PropTypes.string,
};
CardImage.defaultProps = {
	product: {},
	className: 'irjs__card--image',
};

export default CardImage;
