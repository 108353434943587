import usePrevious                                         from 'HOOKS/usePrevious';
import Throttlers                                        from 'HELPERS/throttlers';
import clsx from 'clsx';
import PropTypes                                           from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import RangeSlide from './range/slide';
import RangeTrack from './range/track';
import {
  checkValues,
  convertToPercentage,
  getKeyFromPos,
  getPositionFromValue,
  getPositions,
  getValueFromPos,
  setToNearestStep,
} from './range/utils';

const RangeSlider = (props) => {

  const rangeNode = useRef(null);
  const tNode = useRef(null);
  let isSlideDragging = false;
  const onChangeThrottle = useCallback(Throttlers.throttle(props.onChange, 1000), []);
  const [lastKeyMoved, setLastKey] = useState();
  const [labelPosition, setLabelPosition] = useState({
    min: false,
    max: false,
  });
  const [currentPositions, setPositions] = useState();
  const [values, setValues] = useState({});
  const [percentages, setPercentages] = useState({});
  const curStep = useRef();

  const prevValues = usePrevious(values);

  useEffect(() => {
    setPercentages({
      min: convertToPercentage(props.value?.min || props.minValue, props),
      max: convertToPercentage(props.value?.max || props.maxValue, props),
    });
    setValues({
      min: props.value?.min || props.minValue,
      max: props.value?.max || props.maxValue,
    });

    if (props.allowLabelSwitch && props.value && props.value.max) {
      const minValue = props.value?.min || props.minValue;
      const maxValue = props.value?.max || props.maxValue;

      setLabelPosition({
        max: Boolean(maxValue - minValue <= props.labelCuttOff),
      });
    }

  }, [props.value]);

  useEffect(() => {
    const updateProps = { ...props, step: curStep.current };
    // if (!checkValues(values, updateProps)) {
    //   return;
    // }

    if(prevValues 
      && Object.keys(prevValues).length 
      && values 
      && (prevValues.min !== values.min || prevValues.max !== values.max)){

      setPercentages({
        min: convertToPercentage(values.min || props.minValue, updateProps),
        max: convertToPercentage(values.max || props.maxValue, updateProps),
      });

      onChangeThrottle(values);
    }
  }, [values]);

  const updatePosition = (key, position) => {
    const { width } = tNode.current.getBoundingClientRect();
    const {
      minValue, maxValue, labelCuttOff, allowLabelSwitch,
    } = props;

    const updateProps = { ...props, step: curStep.current };

    let positions = currentPositions;
    if (!positions) {
      positions = getPositions({ ...values }, tNode.current.getBoundingClientRect(), updateProps);
    }
    positions[key] = position;

    const minRatio = Math.round((minValue + (maxValue - minValue) * (positions.min.x / width)) / curStep.current);
    const maxRatio = Math.round((minValue + (maxValue - minValue) * (positions.max.x / width)) / curStep.current);

    const newValues = {
      min: positions.min.x !== 0 && minRatio * curStep.current > props.minValue ? minRatio * curStep.current : props.minValue,
      max: maxRatio * curStep.current > props.maxValue ? props.maxValue : maxRatio * curStep.current,
    };

    if (curStep.current > 1) {
      if (newValues.min % curStep.current > 0 && newValues.min > props.minValue) {
        newValues.min = setToNearestStep(newValues.min, updateProps);
      }
      newValues.max = newValues.max % curStep.current < props.maxValue ? setToNearestStep(newValues.max, updateProps) : newValues.max;
    }

    if (positions.max.x - positions.min.x <= width * 0.2 && allowLabelSwitch) {
      if (key === 'max') {
        if (!labelPosition.min) {
          setLabelPosition({
            ...labelPosition,
            max: true,
          });
        }
      }
      if (key === 'min') {
        if (!labelPosition.max) {
          setLabelPosition({
            ...labelPosition,
            min: true,
          });
        }
      }
    } else {
      setLabelPosition({
        min: false,
        max: false,
      });
    }

    setPositions(positions);
    setValues(newValues);
  };

  const interactionEnd = () => {
    if (isSlideDragging) isSlideDragging = false;
  };

  const setPosition = (posX, key) => {
    setLastKey(key);

    requestAnimationFrame(() => {
      updatePosition(key, {
        x: posX,
        y: 0,
      });
    });
  };

  const getStepFromValue = (value) => {
    const step = Object.entries(props.step).find((range) => {
      const [valMax, rangeStep] = range;
      if (Number(valMax) >= value) return rangeStep;
    });
    const [valMax, rangeStep] = step;
    return rangeStep;
  };

  const handleSlideDrag = (e, key) => {
    if (props.disabled) return;

    const { left, width } = tNode.current.getBoundingClientRect();
    const { clientX } = e.touches ? e.touches[0] : e;

    isSlideDragging = true;

    requestAnimationFrame(() => {

      let key_pos_x = Math.min(Math.max(clientX - left, 0), width);
      let currentValue = getValueFromPos({ x: key_pos_x }, props, tNode.current.getBoundingClientRect());

      const roundedValue = Math.round(currentValue);
      const step = typeof props.step === 'object' ? getStepFromValue(roundedValue) : Number(props.step);
      curStep.current = step;

      if (step > 1 && currentValue % step > 0) currentValue = setToNearestStep(currentValue, { ...props, step });

      if (key === 'min') {
        const { max } = values;
        const max_pos = getPositionFromValue(max, props, tNode.current.getBoundingClientRect());

        if (max_pos.x >= key_pos_x && (max === currentValue || (max - currentValue) <= step)) {

          e.stopPropagation();
          e.preventDefault();
          const minValue = max - step;
          const newPos = getPositionFromValue(minValue, props, tNode.current.getBoundingClientRect());
          key_pos_x = newPos.x;
          setPosition(key_pos_x, key);

        } else {
          setPosition(key_pos_x, key);
        }
      }

      if (key === 'max') {
        const { min } = values;
        const min_pos = getPositionFromValue(min, props, tNode.current.getBoundingClientRect());

        if (min_pos.x <= key_pos_x && (min === currentValue || (currentValue - min) <= step)) {
          
          e.stopPropagation();
          e.preventDefault();
          const maxValue = min + step;
          const newPos = getPositionFromValue(maxValue, props, tNode.current.getBoundingClientRect());
          key_pos_x = newPos.x;
          setPosition(key_pos_x, key);

        } else {
          setPosition(key_pos_x, key);
        }
      }

    });
  };

  const handleMouseUp = (e) => {
    interactionEnd();
    rangeNode.current.ownerDocument.removeEventListener('mouseup', handleMouseUp);
  };
  const handleMouseDown = (e) => {
    rangeNode.current.ownerDocument.removeEventListener('mouseup', handleMouseUp);
    rangeNode.current.ownerDocument.addEventListener('mouseup', handleMouseUp);
  };

  const handleTrackMouseDown = (e, pos) => {
    if (props.disabled || isSlideDragging) return;

    const { min, max } = values;

    e.preventDefault();

    const value = getValueFromPos(pos, props, tNode.current.getBoundingClientRect());

    const roundedValue = Math.round(value);
    const step = typeof props.step === 'object' ? getStepFromValue(roundedValue) : Number(props.step);
    curStep.current = step;

    const stepValue = Math.round(value / step) * step;
    const dragKey = getKeyFromPos({ ...values }, pos, props, tNode.current.getBoundingClientRect());
    setLastKey(dragKey);

    if (stepValue < max || stepValue > min) {
      requestAnimationFrame(() => {
        updatePosition(dragKey, pos);
      });
    }
  };

  return (
    <div className={ clsx('irjs__range', props.className) }>
      {props.label && <label>{props.label}</label>}

      <div className="irjs__range--input">
        {props.showMinMaxLabels && (
          <div className="irjs__range--values">
            <div className="irjs__range--values-min">{props.minValue && <label>{props.minValue || 0}</label>}</div>
            <div className="irjs__range--values-max">{props.maxValue && <label>{props.maxValue || 100}</label>}</div>
          </div>
        )}
        <div className="irjs__rangefield" aria-disabled={ props.disabled } onMouseDown={ handleMouseDown } ref={ rangeNode }>
          <div className="irjs__rangefield--inner">
            <RangeTrack percentages={ percentages } onTrackMouseDown={ handleTrackMouseDown } ref={ tNode }>
              {['min', 'max'].map((key) => {
                let { minValue, maxValue } = props;
                const value = values[key];
                const percentage = percentages[key];

                if (key === 'min') {
                  maxValue = props.value.max;
                } else {
                  minValue = props.value.min;
                }

                return (
                  <RangeSlide
                    formatedLabel={ props.formatedLabel }
                    key={ key }
                    rangeKey={ key }
                    minValue={ minValue }
                    maxValue={ maxValue }
                    value={ value }
                    percentage={ percentage }
                    onSlideDrag={ handleSlideDrag }
                    ariaControls=""
                    ariaLabelledby=""
                    invertedLabel={ labelPosition[key] }
                  />
                );
              })}
            </RangeTrack>
            {['min', 'max'].map((key) => {
              const defaultValue = key === 'min' ? props.minValue : props.maxValue;
              const hiddenValue = (values && values[key]) || defaultValue;
              return <input type="hidden" value={ hiddenValue } key={ key } name={ `rangeslider_value_${key}` } />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  formatedLabel: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.shape()]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  }),
  buffer: PropTypes.number,
  showMinMaxLabels: PropTypes.bool,
  labelCuttOff: PropTypes.number,
  allowLabelSwitch: PropTypes.bool,
  className: PropTypes.string,
};

RangeSlider.defaultProps = {
  onChange: () => {},
  disabled: false,
  formatedLabel: () => {},
  name: null,
  label: null,
  step: 1,
  minValue: 0,
  maxValue: 10,
  value: {
    min: null,
    max: null,
  },
  buffer: 5,
  showMinMaxLabels: false,
  labelCuttOff: 20,
  allowLabelSwitch: false,
  className: '',
};

export default React.memo(RangeSlider);
