export const isHTML = (str) => !(str || '')
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
    
export const isFullUrl = (string) => {
    /* eslint no-control-regex: "error" */
    const regex = /(?:(?:(?:[a-z]+:)?\/\/)|www.)(?:S+(?::S*)?@)?(?:localhost|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#][^s"]*)?/;
    const pattern = new RegExp(regex, 'i'); // fragment locator
    return !!pattern.test(string);
};