import React, { useEffect, useState, useCallback } from 'react';
import PropTypes                                   from 'prop-types';

import Session                                     from 'UTILS/Session';
import Context                                     from 'UTILS/Context';
import { getLangSlug }                             from 'UTILS/LanguageSlugs';
import { convertCssUnit, stringHasUnit }           from 'HELPERS/formatter';

export const InstarecContext = React.createContext({});
InstarecContext.displayName = 'InstarecContext';

export const InstarecProvider = ({ children, parameters }) => {

	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [hasGlobalError, setGlobalError] = useState(false);
	const [questionnaire, setQuestionnaire] = useState();
	const [questions, setQuestions] = useState([]);
	const [choices, setChoices] = useState({});
	const [filters, setFilters] = useState({
		min: null, max: null,
	});
	const [resultActiveSectionType, setActiveSectionTyoe] = useState();
	const [selectedIdx, setSelectedIdx] = useState(parameters?.modifyShowQuestion || 0);
	const [activeQuestionIdx, setActiveQuestionIdx] = useState(0);

	  const [localQuestionnaireData ] = useState(Session.storage.session
			&& Object.keys(Session.storage.session).length
			&& Session.storage.session[`questionnaire_${parameters.questionnaire_id}_choices`]
			&& Session.storage.session[`questionnaire_${parameters.questionnaire_id}_filters`]
				? { choices: Session.storage.session[`questionnaire_${parameters.questionnaire_id}_choices`], filters: Session.storage.session[`questionnaire_${parameters.questionnaire_id}_filters`] }
				: {});


	useEffect(() => {
		if (parameters.questionnaire_id) {
			Context.setQuestionnaireId(parameters.questionnaire_id);
		}
		return () => {
			// do nothing
		};
	}, []);

	const [quizContext, setContextQuiz] = useState({
		questionnaire: {},
		results: {},
		selections:
			Session.storage.session
			&& Object.keys(Session.storage.session).length
			&& Session.storage.session[`questionnaire_${parameters.questionnaire_id}_choices`]
			&& Session.storage.session[`questionnaire_${parameters.questionnaire_id}_filters`]
				? { choices: Session.storage.session[`questionnaire_${parameters.questionnaire_id}_choices`], filters: Session.storage.session[`questionnaire_${parameters.questionnaire_id}_filters`] }
				: {},
	});

	// useEffect set choice/filters onMount

	useEffect(() => {
		if(
			Array.isArray(questions) 
			&& questions.length 
			&& activeQuestionIdx !== null 
			&& activeQuestionIdx >= 0
		) {
			setCurrentQuestion(questions[activeQuestionIdx])
		}	
	}, [questions, activeQuestionIdx]);

	const checkSidemenuFix = () => {
		let stickyTop = parameters.sidemenuStickyTop;
		if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
			stickyTop = convertCssUnit(stickyTop);
		}

		if (Object.keys(parameters).includes('sidemenuStatic')) return parameters.sidemenuStatic;
		if (!Object.keys(parameters).includes('sidemenuStatic') && Object.keys(parameters).includes('sidemenuStickyTop') && stickyTop >= 0) return true;

		return false;
	};

	const setStickyTop = () => {
		let stickyTop = parameters.sidemenuStickyTop;
		if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
			stickyTop = convertCssUnit(stickyTop);
		}
		if (Object.keys(parameters).includes('sidemenuStickyTop')) return parameters.sidemenuStickyTop;
		if (
			!Object.keys(parameters).includes('sidemenuStickyTop')
			&& Object.keys(parameters).includes('sidemenuStatic')
			&& !parameters.sidemenuStatic
		) return 50;

		return false;
	};

	const [params, setParams] = useState({
		...parameters,
		sidemenuStatic: checkSidemenuFix(),
		sidemenuStickyTop: setStickyTop(),
	});

	const setSessionData = (data, pfx = null) => {

		if(pfx && String(pfx).length) {
			data = Object.keys(data).reduce((obj, _key) => {
				obj[`${pfx}_${_key}`] = data[_key];
				return obj;
			}, {});
		}

		Session.createSessionItems(data);
	};

	const clearSessionData = () => {
		Session.clearSessionData();
	};

	const setQuizContext = useCallback(
		(updates) => {
			setContextQuiz(Object.assign(quizContext, { ...updates }));
		},
		[quizContext]
	);

	const fetchCustomClass = (className) => {
		const styles = className.indexOf('.') >= 0 ? className.split('.') : className;
		if (parameters.customStyling && Object.keys(parameters.customStyling).length) {
			const {
				questionnaire, results, sidemenu, ...universal
			} = parameters.customStyling;
			if (Array.isArray(styles) && styles.length) {
				if (
					styles[0] === 'questionnaire'
					&& questionnaire
					&& Object.keys(questionnaire).length
					&& Object.keys(questionnaire).includes(styles[1])
				) {
					return questionnaire[styles[1]].length ? questionnaire[styles[1]] : '';
				}

				if (styles[0] === 'results' && results && Object.keys(results).length && Object.keys(results).includes(styles[1])) {
					return results[styles[1]].length ? results[styles[1]] : '';
				}
				if (styles[0] === 'sidemenu' && sidemenu && Object.keys(sidemenu).length && Object.keys(sidemenu).includes(styles[1])) {
					return sidemenu[styles[1]].length ? sidemenu[styles[1]] : '';
				}
			}

			if (typeof styles === 'string' && Object.keys(universal).length && Object.keys(universal).includes(styles)) {
				return universal[styles];
			}
		}
		return '';
	};

	const getCustomStyling = (styleParams) => {
		if (Array.isArray(styleParams) && styleParams.length) {
			const classes = styleParams.reduce((obj, styleParam) => {
				const cs_class = fetchCustomClass(styleParam);
				if (cs_class.length) {
					obj.push(cs_class);
				}
				return obj;
			}, []);
			return classes.length ? classes[0] : '';
		}

		if (typeof styleParams === 'string') {
			return fetchCustomClass(styleParams);
		}
	};

	const hasDecimalPlaces = () => {
		if (!parameters.showDecimalPlaces) return 0;
		if (parameters.showDecimalPlaces && parameters.numDecimalPlaces) return Number(parameters.numDecimalPlaces);
		return 2;
	};

	const setChoicesFilters = async (questions) => new Promise((resolve, reject) => {
    if(questions.length){
      setChoices(questions.reduce((obj, question) => {
        if(question.type === 'multiple_choice'){
          obj[question.id] = [];
          if(localQuestionnaireData && localQuestionnaireData.choices){
            const questionChoiceIds = question.choices.map((choice) => (choice.id));
            const found = localQuestionnaireData.choices[question.id].some((val) => questionChoiceIds.includes(Number(val)));
            if(found){
              const question_choices = localQuestionnaireData.choices[question.id].filter(item => questionChoiceIds.includes(item));
              obj[question.id] = obj[question.id].concat([...question_choices]);
            }
          }
        }
        return obj;
      }, {}));

      if(localQuestionnaireData && localQuestionnaireData.filters && Object.entries(localQuestionnaireData.filters).length){
        setFilters(Object.entries(localQuestionnaireData.filters).reduce((obj, entry) => {
        	const [key, value] = entry;
          if(value){
            obj[key] = value;
          }
          return obj;
        }, filters));
      }

      resolve(true);

    }
  });

	const instarecContext = {
		getCustomStyling,
		params,
		quizContext,
		hasGlobalError,
		resultActiveSectionType,
		setSessionData,
		setQuizContext,
		setParams,
		setGlobalError,
		setActiveSectionTyoe,
		clearSessionData,
		hasDecimalPlaces,
		questionnaire, 
		setQuestionnaire,
		questions, 
		setQuestions,
		choices, 
		setChoices,
		filters, 
		setFilters,
		setChoicesFilters,
		activeQuestionIdx,
		setActiveQuestionIdx,
		setCurrentQuestion,
		currentQuestion,
		localQuestionnaireData
	};

	return <InstarecContext.Provider value={ instarecContext }>{children}</InstarecContext.Provider>;
};

InstarecProvider.propTypes = {
	integration_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	collection_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	questionnaire_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	openNewTab: PropTypes.bool,
	multipage: PropTypes.bool,
	customStyling: PropTypes.shape(),
	customText: PropTypes.shape(),
	logo_position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
	showAddToCart: PropTypes.bool,
	eplatform: PropTypes.string,
	devMode: PropTypes.bool,
	resultsDisplayedAs: PropTypes.oneOf(['separated', 'merged']),
	resultsPerPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	showDecimalPlaces: PropTypes.bool,
	numDecimalPlaces: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	resultsRedirect: PropTypes.string,
	priceRange: PropTypes.shape({
		min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
	onLoadComplete: PropTypes.func,
	renderProductCards: PropTypes.func,
	error: PropTypes.shape(),
	priceCurrency: PropTypes.string,
	lang: PropTypes.string,
	layout: PropTypes.string,
	sidemenuStatic: PropTypes.bool,
};
InstarecProvider.defaultProps = {
	integration_id: '',
	collection_id: '',
	questionnaire_id: '',
	openNewTab: false,
	multipage: true,
	customStyling: null,
	customText: {
		questionnaireHeading: 'Instant Recommendations',
		resultsHeading: 'Results',
		modifySearchBtn: '',
		getRecsBtn: '',
	},
	logo_position: 'bottom-right',
	showAddToCart: false,
	eplatform: '',
	devMode: false,
	resultsDisplayedAs: 'separated',
	resultsPerPage: 6,
	showDecimalPlaces: true,
	numDecimalPlaces: 2,
	resultsRedirect: null,
	priceRange: {
		min: 0,
		max: 500,
	},
	onLoadComplete: null,
	renderProductCards: null,
	error: null,
	priceCurrency: 'USD',
	lang: 'en',
	layout: 'row',
	sidemenuStatic: false,
};
