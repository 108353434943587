export const mergeResults = (data) => Object.values(data.types).reduce((obj, key) => {
	key.results = key.results.map((result) => {
		result.lookups.map((lookup) => {
			lookup.type = key.type;
			return lookup;
		});
		return result;
	});
	obj = obj.concat(key.results);
	return obj;
}, []);

export const getResultsTotal = (data) => Object.values(data.types).reduce((a, b) => a + b.results.length, 0);
