import Api             from './Api';
import Context         from 'UTILS/Context';
import { getNonLatin } from 'HELPERS/text';

class Questionaire extends Api {
	getQuestionaire = (params) => this.get('questionnaire', params).then((questionnaire) => {
		questionnaire.questions.forEach((question) => {
			question.choices.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));
		});
		questionnaire.questions = questionnaire.questions.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));

		const _order = Object.values(questionnaire.questions).reduce((obj, key, idx) => {
			obj[idx] = key.id;
			return obj;
		}, {});

		return { ...questionnaire, _order };
	});

	postQuestionaire = (params) => {
		const { limit, include_wines, max_results_per_type, max_results_per_page, ...queryParams } = params;

		queryParams.limit = limit + 1;

		if (!max_results_per_page && queryParams.offset + limit > max_results_per_type) {
			queryParams.limit = max_results_per_type - queryParams.offset;
		}

		if(max_results_per_page && queryParams.limit > max_results_per_page){
			queryParams.limit = max_results_per_page;
		}

		queryParams.timeout = Context.getDefaultTimeout() !== null ? Context.getDefaultTimeout() : 0;

		return this.post(`query${include_wines ? '?include_wines=true' : null}`,  {
	      ...queryParams,
	    }).then((results) => {
			if (results && results.types.length) {
				results.types.forEach((resultType, idx, typesArr) => {
					const nonLatinType = getNonLatin(resultType.type);
					const hasNextPage = resultType.results.length > limit;
					if (resultType.results.length > limit) resultType.results = resultType.results.slice(0, limit);
					const result_total = resultType.results.length;
					typesArr[idx] = {
						...resultType,
						hasNextPage,
						nonLatinType,
						result_total,
					};
				});
			}

			const results_total = results.types.reduce((a, b) => a + b.result_total, 0);

			return { ...results, results_total };
		}).catch((err) => Promise.reject(err));
	};
}

const QuestionaireApi = new Questionaire();
export { QuestionaireApi as default };
