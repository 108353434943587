import * as React        from 'react';
import PropTypes         from 'prop-types';

const CardActions = (props) => (
	 <div className="irjs__card--actions">
		{ props.children }
  </div>
);

CardActions.propTypes = {
	children: PropTypes.node,
};
CardActions.defaultProps = {
	children: '',
};

export default CardActions;
