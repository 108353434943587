import React, { useContext, useEffect, useState } from 'react';
import PropTypes                        from 'prop-types';
import clsx                             from 'clsx';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import { InstarecContext } from 'COMPONENTS/InstarecContext';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Emitter from 'UTILS/Emitter';


const Footer = (props) => {
	const { questions, currentQuestion, ...context } = useContext(InstarecContext);

	// minimum_number_of_questions_answered

	const [, updateState] = useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	const disableBack = () => Boolean(context.activeQuestionIdx > 0);

	const disableNext = () => {
		const condition = Boolean(
			context.activeQuestionIdx < Object.keys(context.questionnaire._order).length - 1
				&& currentQuestion
				&& Object.keys(context.choices).includes(String(currentQuestion.id))
				&& context.choices[currentQuestion.id].length >= currentQuestion.minimum_selected
				&& (currentQuestion.maximum_selected === null
					|| (currentQuestion.maximum_selected !== null
						&& context.choices[currentQuestion.id].length <= currentQuestion.maximum_selected))
		);

		return condition;
	};


	useEffect(() => {
    const contextUpdate = () => {
      forceUpdate();
    };

    Emitter.addListener('choicesUpdated', contextUpdate);
    Emitter.addListener('questionnaireCleared', contextUpdate);
    return () => {
      Emitter.removeListener('choicesUpdated', contextUpdate);
      Emitter.removeListener('questionnaireCleared', contextUpdate);
    }
  }, []);

	return (
		<div className="irjs__form--footer">
			<div className="irjs__btn-group">
				<button
					type="button"
					className={ clsx('irjs__btn', context.getCustomStyling('basicButton')) }
					onClick={ () => {
						context.setActiveQuestionIdx(context.activeQuestionIdx !== 0 ? context.activeQuestionIdx - 1 : 0);
						PreferabliAnalytics.track('guidedrec questionnaire back question', {
							from_question: context.activeQuestionIdx,
							to_question: (context.activeQuestionIdx !== 0 ? context.activeQuestionIdx - 1 : 0),
							onlyMp: true,
						});
					} }
					disabled={ !disableBack() }
				>
					{LokaliseLanguage.getSlugTranslation({slug:'questionnaire.backbtn'})}
				</button>
				<button
					type="button"
					className={ clsx(
						'irjs__btn',
						context.activeQuestionIdx === questions.length - 1 && 'irjs__btn--solid',
						context.getCustomStyling('basicButton')
					) }
					onClick={ (e) => {

						e.preventDefault();

						let selection = {};
						if(currentQuestion.type === 'multiple_choice'){
							selection.choices = context.choices[currentQuestion.id].reduce((arr, _value) => {
							  let _tV = '';
							  const _choice = currentQuestion.choices.find((_choice) => (_choice.id === _value));
							  if(_choice && _choice.text) _tV = _choice.text;
							  return [...new Set([...arr, _tV])];
							}, []);
						}

						if (context.filters 
							&& typeof context.filters === 'object' 
							&& currentQuestion.type === 'price_range') {

							const priceMin = currentQuestion.default_price_min || context.questionnaire.default_price_min || 0;
							const priceMax = currentQuestion.default_price_max || context.questionnaire.default_price_max || 500;

							selection = {
							  price_min: (context.filters && context.filters.min >= 0 && (Number(context.filters.min) > priceMin)) ? context.filters.min : null,
							  price_max: (context.filters && context.filters.max && (Number(context.filters.max) < priceMax)) ? context.filters.max : null,
							};
						}

						PreferabliAnalytics.track(`gr selected ${currentQuestion.mixpanel_group_slug}`, {
							onlyMp: true,
							questionnaire_id: context.questionnaire.id,
							question_id: currentQuestion.id,
							question_number: currentQuestion.number,
							...selection
						});

						if (context.activeQuestionIdx < questions.length - 1) {
							context.setActiveQuestionIdx(context.activeQuestionIdx < questions.length - 1 ? context.activeQuestionIdx + 1 : 0);

							PreferabliAnalytics.track('guidedrec questionnaire next question', {
								from_question: context.activeQuestionIdx,
								to_question: context.activeQuestionIdx < questions.length - 1 ? context.activeQuestionIdx + 1 : 0,
								onlyMp: true,
							});
						}
						if (context.activeQuestionIdx === questions.length - 1) {
							e.preventDefault();

							PreferabliAnalytics.track('guidedrec questionnaire submitted', {
								onlyMp: true,
							});

							props.handleFormSubmit();
						}
					} }
					disabled={ context.activeQuestionIdx < questions.length - 1 ? !disableNext() : false }
				>
					{context.activeQuestionIdx < questions.length - 1
						? LokaliseLanguage.getSlugTranslation({slug:'questionnaire.nextbtn'})
						: (context.params.customText && context.params.customText.getRecsBtn)
						  || LokaliseLanguage.getSlugTranslation({slug:'questionnaire.getrecsbtn'})}
				</button>
			</div>

		</div>
	);
};

Footer.propTypes = {
	handleFormSubmit: PropTypes.func,
};

Footer.defaultProps = {
	handleFormSubmit: () => {},
};

export default Footer;
