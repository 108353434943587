import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Context from 'UTILS/Context';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import { InstarecContext, InstarecProvider } from 'COMPONENTS/InstarecContext';
import LoadingDots from 'GLOBAL/LoadingDots';
import MaterialIcon from 'GLOBAL/MaterialIcon';
import Modal from 'COMPONENTS/modal/modal';
import QuestionaireApi from 'API/QuestionaireApi';
import { capitalize } from 'HELPERS/text';
import { formattedRelativePath } from 'HELPERS/url'; 
import { isFullUrl } from 'HELPERS/validator';
import Session from 'UTILS/Session';
import withContext from 'HOC/withContext';
import QuestionnaireForm from './questionnaire';
import PoweredByLogo from 'GLOBAL/PoweredByLogo';
import PreferabliLogo from 'GLOBAL/PreferabliLogo';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Emitter from 'UTILS/Emitter';


import { FormContext } from './context';


const InstarecQuiz = (props) => {
        const context = useContext(InstarecContext);
        const { currentQuestion, questionIdx } = useContext(FormContext);

        const [isLoading, setIsLoading] = useState(true);
        const [hasError, setHasError] = useState(false);

        const [modalOpen, setModalOpen] = useState(false);
        const questionnaireMounted = useRef(false);

        const [startOverDisabled, setStartOverDisabled] = useState(true);

        const isResultPage = () => (isFullUrl(context.params.resultsRedirect) ? window.location.href === context.params.resultsRedirect : window.location.pathname.includes(formattedRelativePath(context.params.resultsRedirect)));

        const handleSelectedQuestion = () => {
            if (Session.storage.session[`questionnaire_${Context.getQuestionnaireId()}_postedquery`] &&
                isResultPage() &&
                props.selectedQuestionIdx &&
                Number(props.selectedQuestionIdx) >= 0
            ) return Number(props.selectedQuestionIdx) - 1;

            if (Session.storage.session[`questionnaire_${Context.getQuestionnaireId()}_postedquery`] &&
                isResultPage() &&
                !props.selectedQuestionIdx &&
                context.questionnaire &&
                Object.keys(context.questionnaire).includes('questions')
            ) {
                return context.questionnaire.questions.findIndex((question) => question.type === 'price_range');
            }

            return 0;
        };

        // this will be removed.
        const handleFetchingQuestionnaire = (e) => {
            if (e) e.preventDefault();

            setIsLoading(true);

            QuestionaireApi.getQuestionaire({id: Number(context.params.questionnaire_id),})
                .then(async (questionnaire) => {
                    // if(context.params.typesPlural){}

                    const _localData = await context.setChoicesFilters(questionnaire.questions);
                    if(_localData){
                        context.setQuestionnaire({...questionnaire});
                        context.setQuestions([...questionnaire.questions]);

                        context.setQuizContext({
                            ...context.quizContext,
                            questionnaire,
                            max_results_per_type: questionnaire.max_results_per_type,
                            offset: 0,
                        });

                        PreferabliAnalytics.track('guidedrec questionnaire loaded', {
                            onlyMp: true,
                            questionnaire_id: Number(context.params.questionnaire_id),
                        });

                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    PreferabliAnalytics.track('guidedrec questionnaire errored', {
                         onlyMp: true,
                         error: 'api',
                        questionnaire_id: Number(context.params.questionnaire_id),
                    });

                    setHasError(true);
                });
        };

        useEffect(() => () => {
                if (questionnaireMounted.current === false) {
                    setModalOpen(false);
                    questionnaireMounted.current = undefined;
                    document.removeEventListener('contextUpdated', contextUpdate, false);
                    setTimeout(()=> {
                    const updateEvent = new CustomEvent('appUnmout_questionnaire', { detail: { type: 'questionnaire' }} );
                    document.dispatchEvent(updateEvent);
                    props.root?.unmount();
                    });
                }
            },
            [questionnaireMounted.current]
        );

        // runs once
        useEffect(() => {
            questionnaireMounted.current = true;
            handleFetchingQuestionnaire();

            if (!modalOpen) setModalOpen(true);

            PreferabliAnalytics.track('guidedrec modal opened', {
                onlyMp: true,
                questionnaire_id: Number(context.params.questionnaire_id),
                page_url: window.location.href,
                page_pathname: window.location.pathname,
            });

            document.addEventListener('contextUpdated', contextUpdate, false);
        }, []);

        const closeModal = (e) => {
            if (e) e.preventDefault();
            if (modalOpen) setModalOpen(false);
            questionnaireMounted.current = false;

            PreferabliAnalytics.track('guidedrec modal closed', {
                onlyMp: true,
                questionnaire_id: Number(context.params.questionnaire_id),
                page_url: window.location.href,
                page_pathname: window.location.pathname,
            });
        };

        const handleModalClose = (e) => {
            e.preventDefault();
        };

        const handleFormSubmit = () => {
            let {
                choices,
                filters,
                offset,
                questionnaire,
                questions,
            } = context;

            offset = Number(offset || 0);

            let questionnaire_choice_ids = choices;

            if (Object.prototype.toString.call(choices) === '[object Object]' && Object.keys(choices).length) {

                const _allowedQuestionIds = context.questions.map((_q) => (_q.id));

                const _filterChoices = Object.entries(context.choices).reduce((obj, entry) => {
                    const [ _qId, values ] = entry;
                    if(_allowedQuestionIds.includes(Number(_qId))) obj[_qId] = values;
                    return obj;
                }, {});

                questionnaire_choice_ids = Object.values(_filterChoices).reduce((arr, _values) => arr.concat(_values.map((value) => Number(value))), []);
            }

            const questionnaire_post = {
                limit: context.params.resultsPerPage || 6,
                sort_by: 'preference',
                questionnaire_id: questionnaire.id,
                questionnaire_choice_ids,
                offset,
                filters,
            };

            const sessionData = {
                post:  questionnaire_post,
                choices:  choices,
                filters:  filters,
                questionnaire:  questionnaire,
                postedquery:  typeof Session.storage.session[`questionnaire_${Context.getQuestionnaireId()}_postedquery`] === 'undefined' ? true : !Session.storage.session[`questionnaire_${Context.getQuestionnaireId()}_postedquery`],
            };

            PreferabliAnalytics.track('guidedrec query posted', {
                onlyMp: true,
                questionnaire_id: questionnaire.id,
                guidedrec_query_choices: JSON.parse(JSON.stringify(questionnaire_choice_ids)),
                guidedrec_query_filters: JSON.parse(JSON.stringify(filters)),
                guidedrec_query_offset: offset,
            });

            context.setSessionData(sessionData, `questionnaire_${Context.getQuestionnaireId()}`);

            closeModal();

            // future prompt for redirect
            window.location.href = isFullUrl(context.params.resultsRedirect) ? context.params.resultsRedirect : `${window.location.origin}${formattedRelativePath(context.params.resultsRedirect)}`;
        };

        const contextUpdate = (event) => {
            const {
                choices
            } = context;
            let hasChoices = false;

            if (choices) {
                hasChoices = Boolean(Object.values(choices).reduce((arr, key, idx) => {
                	if (key.length) { 
                		arr = arr.concat(key);
                	}
                	return arr;
                }, []).length > 0);

                if (context.activeQuestionIdx >= 0 && hasChoices) {
                    setStartOverDisabled(false);
                }
                if (!hasChoices) {
                    setStartOverDisabled(true);
                }
            }
        };

        return ( <> 
        	{ questionnaireMounted.current && (
                <React.StrictMode> 
        		<Modal isOpen={modalOpen} handleClose={ handleModalClose }>
        			<Modal.Content> 
        				{!hasError && 
        					(
        					<div className = {clsx('irjs__questionnaire--container', context.getCustomStyling('questionnaire.container'))} >
        					<>
        						<LoadingDots className = { clsx('fade', isLoading && 'in', !isLoading && 'hidden')}/> 
        						{!isLoading &&
        						context.questionnaire &&
        						Object.keys(context.questionnaire).includes('questions') &&
        						context.questionnaire.questions.length && ( 
    							<>
        							<QuestionnaireForm 
        								submit = { handleFormSubmit }
        								selectedQuestionIdx = { handleSelectedQuestion() } 
        							>
        							<QuestionnaireForm.Heading>
        							<h1> {(context.params.customText && context.params.customText.questionnaireHeading) || LokaliseLanguage.getSlugTranslation({slug:'questionnaire.heading'})} </h1> 
        							<button 
        								type="button"
        								className = {clsx('irjs__btn', 'irjs__btn--close')}
        								title="Close"
        								onClick={(e) => {
                                                PreferabliAnalytics.track('guidedrec modal closed', { onlyMp: true });
                                                closeModal(e);
                                            }
                                        }>
                                        <MaterialIcon icon="close" />
                                    </button>
                                    </QuestionnaireForm.Heading> 
                                    <QuestionnaireForm.Questions />
                                    </QuestionnaireForm> 
                                </>
                                )} 
                            </> 
                            </div>
                            )
                        }

                        {hasError && ( 
                        	<div className="irjs__error">
                        	<h1>{ LokaliseLanguage.getSlugTranslation({slug:'guidedrec.reload.heading'}) } </h1> 
                        	<h3>
                        		{ LokaliseLanguage.getSlugTranslation({slug:'guidedrec.reload.copy1'}) } 
                        		<br />
                                { LokaliseLanguage.getSlugTranslation({slug:'guidedrec.reload.copy2'}) } 
                            </h3> 
                            <div className="irjs__row">
                            	<button 
	                            	type="button"
	                                className="irjs__btn irjs__btn--solid"
	                                style = {{ margin: '0 10px' }}
	                                onClick = {() => { 
                                        PreferabliAnalytics.track('guidedrec modal reloaded', { onlyMp: true });
                                        handleFetchingQuestionnaire(); 
                                    }} 
                                >
                                { LokaliseLanguage.getSlugTranslation({slug:'questionnaire.errorreload'}) }
                                </button> 
                                <button 
                                	type="button"
                                	className="irjs__btn"
                                	style = {{ margin: '0 10px' }}
                                	onClick = {() => { closeModal(); }}
                            	>
                                { LokaliseLanguage.getSlugTranslation({slug:'questionnaire.errorclose'})} 
                                </button> 
                            </div> 
                            </div>
                        )} 
                        </Modal.Content> 
                        <Modal.Footer> 
                        { context.params.showQuestionNumbers &&
                        context.questionnaire &&
                        context.questionnaire.questions && ( 
                        	<>
                        	<div className="irjs__pg-indicator">
                        	<span> 
                        	{ `${LokaliseLanguage.getSlugTranslation({slug:'questionnaire.questionindex'})}: ${context.activeQuestionIdx + 1} / ${context.questions.length}`} 
                        	</span> 
                        	</div> 
                        	<div className={clsx('irjs__alert')}>
                        		<button 
                        			type="button"
                        			className="irjs__btn irjs__btn--sm irjs__btn--startover"
                        			onClick = {(e) => {
                                        e.preventDefault();

                                        Session.updateSessionData(`questionnaire_${Context.getQuestionnaireId()}_selections`, { choices: {}, filters: [] });
                                        Session.updateSessionData(`questionnaire_${Context.getQuestionnaireId()}_postedquery`, false);
                                        context.setActiveIndex(0);

                                        context.setQuizContext({
                                            ...context.quizContext,
                                            selections: {
                                                choices: {},
                                                filters: [],
                                            },
                                        });

                                        setTimeout(() => {
                                            const updateEvent = new CustomEvent('contextUpdated', { detail: true });
                                            document.dispatchEvent(updateEvent);

                                            const clearValuesEvent = new CustomEvent('contextSelectionsCleared', { detail: true });
                                            document.dispatchEvent(clearValuesEvent);
                                        });

                                        PreferabliAnalytics.track('guidedrec questionnaire start over', {
                                            onlyMp: true,
                                            questionnaire_id: Number(context.params.questionnaire_id),
                                            start_over_from: context.activeQuestionIdx,
                                            start_over_question_id: context.questionnaire.questions[context.activeQuestionIdx].id,
                                        });

                                    }}
                                    disabled={ startOverDisabled }
                                >
                                    { LokaliseLanguage.getSlugTranslation({slug:'questionnaire.startoverbtn'})} 
                                </button> 
                            </div> 
                            </>
                        )} 
                        <div className="irjs__logo irjs__logo--static irjs__logo--sm">
                            {!context.params.preferabli_logo && <PoweredByLogo logoNumber={context.params.logo_option}/>}
                             {context.params.preferabli_logo && <PreferabliLogo logoNumber={context.params.logo_option}/>}
                       	</div> 
                       	</Modal.Footer> 
                   	</Modal>
                    </React.StrictMode>
                   	)} 
                   	</>
               	);
};

InstarecQuiz.propTypes = {
    selectedQuestionIdx: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}; InstarecQuiz.defaultProps = {
    selectedQuestionIdx: null,
};

export default withContext(InstarecProvider, InstarecQuiz);