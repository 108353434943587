import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import clsx                 from 'clsx';

import ModalContent         from './modalcontent';
import ModalFooter          from './modalfooter';
import ModalOveraly         from './modaloverlay';
import ModalPortal          from './modalportal';

const Modal = ({ children, ...props }) => (
	<>
		{props.isOpen && (
			<ModalPortal elementRoot=".irjs__modal">
				<div className={ clsx('irjs__modal-dialog', props.isOpen && 'shown') }>{children}</div>
				<ModalOveraly />
			</ModalPortal>
		)}
	</>
);

Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

Modal.propTypes = {
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
};

Modal.defaultProps = {
	isOpen: false,
	handleClose: () => {},
};

export { Modal as default };
