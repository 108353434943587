import MixpanelTracking      from './Mixpanel';
import GoogleTracking      from './Google';
import { underscore } from 'HELPERS/text';
import Context from 'UTILS/Context';

class PreferabliAnalytics{
  constructor(){
  }

  track(event, params){
     const { onlyMp, onlyGoogle, ...eventParams } = params;

     if(onlyMp) MixpanelTracking.track(`${underscore(event)}`, { ...eventParams, integration_id: Context.getIntegrationId() });
     if(onlyGoogle) GoogleTracking.track(`${underscore(event)}`, { ...eventParams });

     if(!onlyGoogle && !onlyMp){
        MixpanelTracking.track(`${underscore(event)}`, { ...eventParams, integration_id: Context.getIntegrationId() });
        GoogleTracking.track(`${underscore(event)}`, { ...eventParams });  
     }
  }
  
}

const Analytics = new PreferabliAnalytics();
export default Analytics;