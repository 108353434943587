import React, { forwardRef, useEffect, useContext, useState } from 'react';
import PropTypes                                  from 'prop-types';
import styled                                     from 'styled-components';
import clsx                                       from 'clsx';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import { InstarecContext } from 'COMPONENTS/InstarecContext';
import { localePrice } from 'HELPERS/price';

import InputSelectorGroup from 'COMPONENTS/Input/InputSelectorGroup';
import RangeSlider from 'COMPONENTS/Input/InputRangeSlider';

import LokaliseText from 'GLOBAL/LokaliseText';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import usePrevious from 'HOOKS/usePrevious';
import Emitter from 'UTILS/Emitter';


const Question = forwardRef(function Question(prop, ref) {

	const context = useContext(InstarecContext);
	const [questionValues, setQuestionValues] = useState(prop.values);
	const [multiChoices, setMultiChoices] = useState(prop.choices || []);
	const prevValues = usePrevious(questionValues);

	useEffect(() => {
		PreferabliAnalytics.track(`guided rec question viewed`, {
			onlyMp: true, 
			question_id: prop.id, 
			question_type: prop.type, 
			questionnaire_id: context.questionnaire.id
		});
	}, []);

	useEffect(() => {
		setMultiChoices(sortSelectableChoices(prop.choices));
	}, [prop.choices]);

	useEffect(() => {
		setQuestionValues(prop.values);
	}, [prop.values]);


	useEffect(() => {
		if (prop.type === 'multiple_choice') {
		  if(prevValues && questionValues && prevValues.length !== questionValues.length) {
		    const tmpValues = questionValues;
		    const filteredQuestions = context.questions.filter((_ques) => _ques.type === 'multiple_choice' && _ques.id !== prop.id && context.choices[_ques.id].length);

		    const _questionChoiceIds = prop.choices.reduce((arr, choice, idx) => {
		      return [...new Set([...arr, choice.id])];
		    }, []);

		    if(filteredQuestions.length) {
		      filteredQuestions.forEach((_question) => {
		        const choicesWithRequied = _question.choices.filter((_choice) => _choice.requires_choice_ids.length);
		        const questionRequiredIds = choicesWithRequied.reduce((arr, choice, idx) => {
		          const { requires_choice_ids } = choice;
		          if (requires_choice_ids.length) {
		            arr = [...new Set([...arr, ...requires_choice_ids])];
		          }
		          return arr;
		        }, []);

		        if(questionRequiredIds.some((val) => _questionChoiceIds.includes(val))){
		          if (!questionRequiredIds.some((val) => tmpValues.includes(Number(val)))) {
		            prop.onChange(_question.id, []);
		          }
		        }
		      });
		    }
		  }
		}

		if(prop.type === 'price_range' && typeof questionValues == 'object'){
		}

		setTimeout(() => {
			  Emitter.emit('choicesUpdated');
		}, 500);

		return () => {
		  //
		};

	}, [questionValues, prevValues]);


	useEffect(() => {
		const clearQuestionValue = (event) => {
			setQuestionValues(prop.type === 'multiple_choice' ? [] : {});
		};

		document.addEventListener('contextSelectionsCleared', clearQuestionValue, false);
		return () => {
			document.removeEventListener('contextSelectionsCleared', clearQuestionValue, false);
		};
	}, []);

	const setQuestionChoices = ({ key, value, fieldValue }) => {
		const tmpValues = (!questionValues) ? [] : questionValues;
	    const choiceId = Number(fieldValue);
	    const values = [...tmpValues];
	    let question_selection = [];
	    

	    if(prop.maximum_selected === 1){ // only one
	      if(value) question_selection = [...new Set([choiceId])];
	      if(!value) question_selection = [];
	    }

	    if(!prop.maximum_selected || prop.maximum_selected > 1){
	      if (value) {
	        const newValueSet = [...tmpValues, choiceId].sort((a, b) => a - b);
	        question_selection = [...new Set(newValueSet)];
	      }
	      if (!value) {
	        question_selection = values.filter((choice) => choice !== choiceId);
	      }
	    }

		if(typeof prop.onChange === 'function') prop.onChange(prop.id, question_selection);
		setQuestionValues(question_selection);
	};

	// useEffect(() => {
	// 	if (prop.type === 'multiple_choice') {
	// 		prop.onChange(prop.id, values);
	// 	}
	// }, [values]);

	const isSelected = (choice) => {
		let selections = false;

		let questionID = prop.id;
		const { id, requires_choice_ids } = choice;
		const { choices } = context;

		if (typeof prop.values === 'array' && prop.values.length) selections = prop.values;

		if (
			!selections
			&& context
			&& context.choices
			&& Object.keys(context.choices).includes(String(questionID))
			&& context.choices[questionID].length
		) {
			selections = context.choices[questionID];
		}

		return selections && selections.length ? Boolean(selections.find((choice) => Number(choice) === id)) : false;
	};

	const hasRequiredChoices = (choice) => {
		const { requires_choice_ids, id } = choice;
		const { choices } = context;

		if (choices) {
			const allChoices = Object.values(choices).reduce((arr, key, idx) => {
				if (key.length) {
					arr = arr.concat(key);
				}
				return arr;
			}, []);

			return ((allChoices.length && requires_choice_ids.length && requires_choice_ids.some((val) => allChoices.includes(Number(val)))) || false );
		}

		return false;
	};


	const sortSelectableChoices = (choices) => {

		const choicesHasRequired = choices.some((choice) => choice.requires_choice_ids.length > 0);

		if(!choicesHasRequired){
			return choices;
		}
		if(choicesHasRequired){
			return choices.sort((a, b) => {
				if(!hasRequiredChoices(a) > !hasRequiredChoices(b)) return 1;
				if(!hasRequiredChoices(a) < !hasRequiredChoices(b)) return -1;
				return 0;
			});
		}
	}

	const stepStaggered = () => {
		const steps = {
			100: 5,
			250: 25,
			500: 50,
			1000: 100,
		};

		if (Number(context.questionnaire.default_price_max) - 1000 < 500) {
			delete steps[1000];
			steps[Number(context.questionnaire.default_price_max)] = 100;
		}
		if (Number(context.questionnaire.default_price_max) - 1000 > 1000) {
			steps[Number(context.questionnaire.default_price_max)] = 200;
		}

		return steps;
	};

	const checkChoiceValidation = (choice) => {
		if (!choice.requires_choice_ids.length) return true;
		if (choice.requires_choice_ids.length && hasRequiredChoices(choice)) return true;
		return false;
	};


	return (
		<div className="irjs__question">
			<div className={ clsx('irjs__question--heading', context.getCustomStyling('questionnaire.heading')) }>
				<h1>{prop.text}</h1>
				{prop.type === 'multiple_choice' && (
		          <span>
		           <LokaliseText tag='em' slug={ `questionnaire.${prop.minimum_selected === 1 ? 'required' : 'optional'}` }  />
		          </span>
		        )}

		        {prop.type === 'multiple_choice' && (
		          <span className={clsx('')}>
		           <em>{LokaliseLanguage.getSlugTranslation({slug:`questionnaire.${(prop.maximum_selected === 1) ? 'selectonlyone' : 'selectonemore'}` , canTitleize: false})}</em>
		          </span>
		        )}

		        {prop.type === 'price_range' && (
		          <span className={ clsx('') }>
		            <LokaliseText tag='em' slug='questionnaire.optional' />
		          </span>
		        )}
			</div>
			<div className="irjs__question--selection">
				{prop.type === 'multiple_choice' && (

					<InputSelectorGroup
			            className={ clsx('irjs__selector-group', prop.number === 1 && 'irjs__selector-group-single') }
			            type={prop.maximum_selected === 1 ? 'radio' : 'checkbox'}
			            style='pill'
			            name={ 'selector-choice' }
			            value={ prop.maximum_selected === 1 ? context.choices[prop.id][0] : null}
			            fields={ prop.choices.map((choice, i) => {
			            	return {
				                className: ['irjs__selector--choice', context.getCustomStyling('questionnaire.selector')],
				                id:  `choice-${prop.id}-${choice.id}-${choice.number}`,
				                value:  choice.id,
				                label:  choice.text,
				                disabled:  choice.requires_choice_ids.length ? !hasRequiredChoices(choice) : false,
				                checked: () => isSelected(choice),
				                onClickValidate: () => checkChoiceValidation(choice),
				            };
				          }
			            ) }
			            onClick={ (option) => {
			              setQuestionChoices(option);

			              PreferabliAnalytics.track(`guided rec choice ${option.value ? 'selected' : 'deselected'}`, {
			                  onlyMp: true,
			                  questionnaire_id: context.questionnaire.id,
			                  question_number: prop.number,
			                  question_id: prop.id,
			                  question_group_slug: prop.mixpanel_group_slug,
			                  choice_id: option.fieldValue,
			                  choice_selected: option.value,
			                  choice_label: option.label,
			                });

			             }}
			          />
				)}
				{prop.type === 'price_range' && (
					<>
						<RangeSlider
							name="irjs__range-field"
							minValue={ prop.default_price_min || context.questionnaire.default_price_min || context.params.priceRange.min || 0 }
							maxValue={ prop.default_price_max || context.questionnaire.default_price_max || context.params.priceRange.max || 500 }
							step={ Number(prop.default_price_increment || context.questionnaire.default_price_max) > 1000 ? stepStaggered() : prop.default_price_increment || context.questionnaire.default_price_increment }
							onChange={ (price_values) => {
								prop.onChange(prop.id, price_values);

								PreferabliAnalytics.track(`guided rec price filter updated`, {
				                  onlyMp: true,
				                  questionnaire_id: context.questionnaire.id,
				                  question_number: prop.number,
				                  question_id: prop.id,
				                  question_group_slug: prop.mixpanel_group_slug,
					                 ...price_values
				                });
							} }
							formatedLabel={ (x) => {
								if (x === (context.questionnaire.default_price_max || context.params.priceRange.max || 500)) {
									return `${localePrice(
										x,
										context.questionnaire.default_currency,
										context.showPriceSliderCents ? context.hasDecimalPlaces() : 0
									)}+`;
								}
								return `${localePrice(
									x,
									context.questionnaire.default_currency,
									context.showPriceSliderCents ? context.hasDecimalPlaces() : 0
								)}`;
							} }
							labelCuttOff={ 100 }
							allowLabelSwitch
							values={ 
								Object.entries(context.filters).reduce((obj, entry) => {
									const [priceKey, value] = entry;
									obj[priceKey] = value;
									return obj;
								}, { min: null, max: null}) 
							}
						/>
					</>
				)}
			</div>
		</div>
	);

});

Question.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.number,
	choices: PropTypes.arrayOf(PropTypes.object),
	values: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])), PropTypes.shape()]),
	number: PropTypes.number,
	onChange: PropTypes.func,
};
Question.defaultProps = {
	text: '',
	type: 'multiple_choice',
	id: 1,
	number: 1,
	choices: [],
	values: null,
	onChange: () => {},
};

export default Question;
