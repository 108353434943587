import React, {
	useEffect, useContext, useState, useRef,
} from 'react';
import PropTypes                                          from 'prop-types';
import clsx                                               from 'clsx';

import Context                                            from 'UTILS/Context';

import { InstarecContext }                                from 'COMPONENTS/InstarecContext';

import Questions                                          from './questions';
import Footer                                             from './footer';
import FormHeading                                        from './wrappers/formheading';

const QuestionnaireForm = ({
	children, submit, selectedQuestionIdx, ...props
}) => {
	const context = useContext(InstarecContext);

	useEffect(() => {
		if(selectedQuestionIdx && selectedQuestionIdx >= 0) context.setSelectedIdx(selectedQuestionIdx);
	}, [selectedQuestionIdx]);

	return (
	<>
		{context.params.multipage && (
		<div className="irjs__form--container">
			{children}
			<Footer handleFormSubmit={ submit } />
		</div>
		)}
	</>
	);
};

// Child Components
QuestionnaireForm.Heading = FormHeading;
QuestionnaireForm.Questions = Questions;

QuestionnaireForm.propTypes = {
	questions: PropTypes.arrayOf(PropTypes.shape()),
	submit: PropTypes.func,
	selectedQuestionIdx: PropTypes.number,
};
QuestionnaireForm.defaultProps = {
	questions: [],
	submit: () => {},
	selectedQuestionIdx: 1,
};

export default QuestionnaireForm;
