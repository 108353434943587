import axios from 'axios';
import qs       from 'qs';
import Context  from 'UTILS/Context';


const parseData = (data, hasFile) => {
	if (!hasFile) {
		return data;
	}
	const parsedData = new FormData();
	Object.keys(data).forEach((field) => (data[field] ? parsedData.append(field, data[field]) : null));

	return parsedData;
};

export default class Api {
	constructor() {
		this.client = axios.create({
			baseURL: `https://api.preferabli.com/api/6.1`,
			paramsSerializer: (params) => qs.stringify(params, {
				arrayFormat: 'brackets',
				encode: false,
			}),
			headers: {
				'Cache-Control': 'no-cache',
				client_interface: window.location.origin,
			}
		});

		this.createOrUpdate = this.createOrUpdate.bind(this);
		this.delete = this.delete.bind(this);
		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.update = this.update.bind(this);

		this.client.interceptors.request.use(
	      (request) => {
			request.baseURL = `${request.baseURL}/integrations/${Context.getIntegrationId() || Context.integration_id}/`;	       	
	        return request;
	      },
	      (error) => Promise.reject(error)
	    );
	}

	createOrUpdate(api, params, formData = false) {
		const { id, ...data } = params;
		const post_data = data.data ? data.data : data;
		const parsedData = parseData(post_data, formData);

		const promise = id ? this.client.put : this.client.post;

		return promise(
			`${api}${
				id ? `/${id}` : ''
			}`,
			!formData ? post_data : parsedData
		).then(
			(res) => {
				if (res.status >= 200 && res.status < 300 && res.data) {
					return res.data;
				}
				return null;
			},
			(error) => {
				throw error.response;
			}
		);
	}

	delete(api, params = {}) {
		const { id, apiEnv, ...data } = params;

		return this.client
			.delete(
				`${api}${
					id ? `/${id}` : ''
				}`
			)
			.then((res) => {
				if (res.status >= 200 && res.status < 300 && res.data) {
					return res.data;
				}
				return null;
			})
			.catch((error) => {});
	}

	get(api, data = {}) {
		const {
			id, api_version, apiEnv, ...params
		} = data;

		return this.client
			.get(
				`${api}${
					id ? `/${id}` : ''
				}`,
				{
					params: {
						...params,
					},
				}
			)
			.then(
				(res) => {
					if (res.status >= 200 && res.status < 300 && res.data) {
						return res.data;
					}
					return null;
				},
				(error) => {
					throw error.response;
				}
			);
	}

	getBlob(api, params = {}) {
		const { id, apiEnv } = params;

		return this.client
			.get(
				`${api}${
					id ? `/${id}` : ''
				}`,
				{
					responseType: 'arraybuffer',
					params: {
						...params,
					},
				}
			)
			.then(
				(res) => {
					if (res.status >= 200 && res.status < 300 && res.data) {
						return res.data;
					}
					return null;
				},
				(error) => {
					throw error.response;
				}
			);
	}

	post(api, params, formData = false) {
		const { apiEnv, timeout, ...data } = params;
		const post_data = data.data ? data.data : data;
		const parsedData = parseData(post_data, formData);

		const config = {};

		if (timeout) {
			config.timeout = timeout;
		}

		return this.client
			.post(
				`${api}`,
				!formData ? post_data : parsedData,
				{ ...config }
			)
			.then(
				(res) => {
					if (res.status >= 200 && res.status < 300 && res.data) {
						return res.data;
					}
					return null;
				},
				(error) => {
					throw error.response;
				}
			);
	}

	update(api, params) {
		const { id, apiEnv, ...data } = params;

		return this.client
			.put(
				`${api}${
					id ? `/${id}` : ''
				}`,
				data.data ? data.data : data
			)
			.then(
				(res) => {
					if (res.status >= 200 && res.status < 300 && res.data) {
						return res.data;
					}
					return null;
				},
				(error) => {
					throw error.response;
				}
			);
	}
}
