class AppContext {
	constructor() {
		this.integration_id = null;
		this.questionnaire_id = null;
		this.defaultTimeout = null;
		this._appPrefix = 'preferabliGuidedRec';
		this.appOptions = null;
		this.lang = null;
	}

	getQuestionnaireId = () => this.questionnaire_id;

	getIntegrationId = () => this.integration_id;

	getDefaultTimeout = () => this.defaultTimeout;

	getLanguage = () => this.lang;

	setQuestionnaireId = (questionnaire_id) => {
		this.questionnaire_id = questionnaire_id;
	};

	setIntegrationId = (integration_id) => {
		this.integration_id = integration_id;
	};

	setDefaultTimeout = (timeoutDur) => {
		this.defaultTimeout = timeoutDur;
	};

	setAppOptions = (options) => {
		this.appOptions = options;
	}

	getAppOption = (prop) => {
		return this.appOptions[prop] || null;
	}

	setLanguage = (lang) => {
		this.lang = lang;
	}


}

const Context = new AppContext();
export { Context as default };
