import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { InstarecContext } from 'COMPONENTS/InstarecContext';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';


const Link = ({ children, ...props }) => {
	const { capture, ...linkProps } = props;
	const context = useContext(InstarecContext);
	const openNewTab = context.openNewTab || false;

	const captureClick = () => {
		PreferabliAnalytics.track('guidedrec product clickthru', { 
			...capture, 
			onlyMp: true
		});
	};

	return (
		<a
			{ ...linkProps }
			onClick={ () => {
				captureClick();
			} }
			target={ openNewTab ? '_blank' : '_self' }
			rel="noreferrer"
		>
			{children}
		</a>
	);
};

Link.propTypes = {
	children: PropTypes.node,
	product: PropTypes.shape(),
};
Link.defaultProps = {
	product: null,
};

export default Link;
