const throttle = (callback, delay) => {
  let throttleTimeout = null;
  let storedEvent = null;

  const throttledEventHandler = (event) => {
    const shouldExecuteCallback = !throttleTimeout;
    storedEvent = event;
    if (shouldExecuteCallback) {
      callback(storedEvent);
      storedEvent = null;
      throttleTimeout = setTimeout(() => {
        throttleTimeout = null;
        if (storedEvent) {
          throttledEventHandler(storedEvent);
        }
      }, delay);
    }
  };

  return throttledEventHandler;
};

const debounce = (func, wait, immediate = false) => (...args) =>
  setTimeout(() => {
    !immediate && func(...args)
  }, wait);

export default Object.assign({}, {
throttle,
debounce
});