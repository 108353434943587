import { underscore } from 'HELPERS/text';
import mixpanelbrowser from 'mixpanel-browser';

class MixpanelTracking{
  constructor(){
    this.mp = mixpanelbrowser.init(__MIXPANEL__, { 
      batch_requests: true,
      ignore_dnt: true,
      track_pageview: true,
      opt_out_tracking_persistence_type: 'cookie',
    }, 'guidedrec');
  }

  track(event, params){
     this.mp.track(`${underscore(event)}`, { ...params });
  }

  addGroup(integration_id){
    const _group = this.mp.get_group('integration_id', integration_id);
    if(!_group) this.mp.add_group('integration_id', integration_id);
  }

  setGroup(group_key, group){
    this.mp.set_group(group_key, group);
  }

  identify(integration_id){
    this.mp.identify(integration_id);
  }
}

const mp = new MixpanelTracking();

export { mp as default };